var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OpeLiveV1Client } from '../singcolor-proto/ts/Ope_live_v1ServiceClientPb';
import { OpeGetLiveRequest, OpeListLivesRequest, GetRecordHLSDataV1Request, ForceStopLiveRequest, WarnLiveRequest, ListReviewLivesRequest } from '../singcolor-proto/ts/ope_live_v1_pb';
import { SimplePaginationRequest } from '../singcolor-proto/ts/page_entity_pb';
var OpeLiveGrpcClient = /** @class */ (function () {
    function OpeLiveGrpcClient(metadataBuilder) {
        this.metadataBuilder = metadataBuilder;
        this.client = new OpeLiveV1Client(process.env.NEXT_PUBLIC_OPE_API_URL, null, null);
    }
    OpeLiveGrpcClient.prototype.callGrpc = function (invoke) {
        return __awaiter(this, void 0, void 0, function () {
            var metadata, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.metadataBuilder()];
                    case 1:
                        metadata = _a.sent();
                        return [4 /*yield*/, invoke({ metadata: metadata })];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response.toObject()];
                }
            });
        });
    };
    OpeLiveGrpcClient.prototype.listLives = function (_a) {
        var _b = _a.onAir, onAir = _b === void 0 ? false : _b, _c = _a.page, page = _c === void 0 ? 1 : _c, _d = _a.limit, limit = _d === void 0 ? 100 : _d, _e = _a.liveTitleKeyword, liveTitleKeyword = _e === void 0 ? '' : _e;
        return __awaiter(this, void 0, void 0, function () {
            var request, pagination;
            var _this = this;
            return __generator(this, function (_f) {
                request = new OpeListLivesRequest();
                request.setOnAir(onAir || false);
                pagination = new SimplePaginationRequest();
                pagination.setPage(page);
                pagination.setLimit(limit);
                request.setPage(pagination);
                request.setLiveTitleKeyword(liveTitleKeyword);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listLives(request, metadata);
                    })];
            });
        });
    };
    OpeLiveGrpcClient.prototype.getLive = function (liveId) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new OpeGetLiveRequest();
                request.setLiveId(liveId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getLive(request, metadata);
                    })];
            });
        });
    };
    OpeLiveGrpcClient.prototype.forceStopLive = function (liveId, params) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new ForceStopLiveRequest();
                request.setLiveId(liveId);
                if (params.reportId) {
                    request.setReportId(params.reportId);
                }
                request.setViolationCategoryId(params.violationCategoryId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.forceStopLive(request, metadata);
                    })];
            });
        });
    };
    OpeLiveGrpcClient.prototype.warnLive = function (liveId, params) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new WarnLiveRequest();
                request.setLiveId(liveId);
                if (params.reportId) {
                    request.setReportId(params.reportId);
                }
                request.setViolationCategoryId(params.violationCategoryId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.warnLive(request, metadata);
                    })];
            });
        });
    };
    OpeLiveGrpcClient.prototype.getRecordHLSData = function (_a) {
        var liveId = _a.liveId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetRecordHLSDataV1Request();
                request.setLiveId(liveId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getRecordHLSData(request, metadata);
                    })];
            });
        });
    };
    OpeLiveGrpcClient.prototype.listLiveReviews = function (limit) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new ListReviewLivesRequest().setLimit(limit);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listReviewLives(request, metadata);
                    })];
            });
        });
    };
    return OpeLiveGrpcClient;
}());
export { OpeLiveGrpcClient };
