"use strict";
// source: ope_live_v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var page_entity_pb = require('./page_entity_pb.js');
goog.object.extend(proto, page_entity_pb);
var lives_entity_pb = require('./lives_entity_pb.js');
goog.object.extend(proto, lives_entity_pb);
var karaoke_entity_pb = require('./karaoke_entity_pb.js');
goog.object.extend(proto, karaoke_entity_pb);
goog.exportSymbol('proto.jp.singcolor.ForceStopLiveRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ForceStopLiveResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetRecordHLSDataV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetRecordHLSDataV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListReviewLivesRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ListReviewLivesResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeGetLiveRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeGetLiveResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeListLivesRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeListLivesResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeLive', null, global);
goog.exportSymbol('proto.jp.singcolor.ReviewLive', null, global);
goog.exportSymbol('proto.jp.singcolor.WarnLiveRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.WarnLiveResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeLive = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeLive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeLive.displayName = 'proto.jp.singcolor.OpeLive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ReviewLive = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ReviewLive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ReviewLive.displayName = 'proto.jp.singcolor.ReviewLive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeGetLiveRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeGetLiveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeGetLiveRequest.displayName = 'proto.jp.singcolor.OpeGetLiveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeGetLiveResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeGetLiveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeGetLiveResponse.displayName = 'proto.jp.singcolor.OpeGetLiveResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeListLivesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeListLivesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeListLivesRequest.displayName = 'proto.jp.singcolor.OpeListLivesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeListLivesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.OpeListLivesResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.OpeListLivesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeListLivesResponse.displayName = 'proto.jp.singcolor.OpeListLivesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.WarnLiveRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.WarnLiveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.WarnLiveRequest.displayName = 'proto.jp.singcolor.WarnLiveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.WarnLiveResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.WarnLiveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.WarnLiveResponse.displayName = 'proto.jp.singcolor.WarnLiveResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ForceStopLiveRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ForceStopLiveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ForceStopLiveRequest.displayName = 'proto.jp.singcolor.ForceStopLiveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ForceStopLiveResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ForceStopLiveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ForceStopLiveResponse.displayName = 'proto.jp.singcolor.ForceStopLiveResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListReviewLivesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListReviewLivesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListReviewLivesRequest.displayName = 'proto.jp.singcolor.ListReviewLivesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListReviewLivesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListReviewLivesResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListReviewLivesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListReviewLivesResponse.displayName = 'proto.jp.singcolor.ListReviewLivesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetRecordHLSDataV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetRecordHLSDataV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetRecordHLSDataV1Request.displayName = 'proto.jp.singcolor.GetRecordHLSDataV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetRecordHLSDataV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetRecordHLSDataV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetRecordHLSDataV1Response.displayName = 'proto.jp.singcolor.GetRecordHLSDataV1Response';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeLive.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeLive.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeLive} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeLive.toObject = function (includeInstance, msg) {
        var f, obj = {
            live: (f = msg.getLive()) && lives_entity_pb.Live.toObject(includeInstance, f),
            lastReviewedAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
            numberOfViewingListeners: jspb.Message.getFieldWithDefault(msg, 3, 0),
            isSinging: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
            hlsUrl: jspb.Message.getFieldWithDefault(msg, 5, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeLive}
 */
proto.jp.singcolor.OpeLive.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeLive;
    return proto.jp.singcolor.OpeLive.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeLive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeLive}
 */
proto.jp.singcolor.OpeLive.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new lives_entity_pb.Live;
                reader.readMessage(value, lives_entity_pb.Live.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLastReviewedAt(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNumberOfViewingListeners(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsSinging(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setHlsUrl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeLive.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeLive.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeLive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeLive.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(1, f, lives_entity_pb.Live.serializeBinaryToWriter);
    }
    f = message.getLastReviewedAt();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getNumberOfViewingListeners();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getIsSinging();
    if (f) {
        writer.writeBool(4, f);
    }
    f = message.getHlsUrl();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
};
/**
 * optional Live live = 1;
 * @return {?proto.Live}
 */
proto.jp.singcolor.OpeLive.prototype.getLive = function () {
    return /** @type{?proto.Live} */ (jspb.Message.getWrapperField(this, lives_entity_pb.Live, 1));
};
/**
 * @param {?proto.Live|undefined} value
 * @return {!proto.jp.singcolor.OpeLive} returns this
*/
proto.jp.singcolor.OpeLive.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeLive} returns this
 */
proto.jp.singcolor.OpeLive.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeLive.prototype.hasLive = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional int64 last_reviewed_at = 2;
 * @return {number}
 */
proto.jp.singcolor.OpeLive.prototype.getLastReviewedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OpeLive} returns this
 */
proto.jp.singcolor.OpeLive.prototype.setLastReviewedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 number_of_viewing_listeners = 3;
 * @return {number}
 */
proto.jp.singcolor.OpeLive.prototype.getNumberOfViewingListeners = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OpeLive} returns this
 */
proto.jp.singcolor.OpeLive.prototype.setNumberOfViewingListeners = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional bool is_singing = 4;
 * @return {boolean}
 */
proto.jp.singcolor.OpeLive.prototype.getIsSinging = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.OpeLive} returns this
 */
proto.jp.singcolor.OpeLive.prototype.setIsSinging = function (value) {
    return jspb.Message.setProto3BooleanField(this, 4, value);
};
/**
 * optional string hls_url = 5;
 * @return {string}
 */
proto.jp.singcolor.OpeLive.prototype.getHlsUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeLive} returns this
 */
proto.jp.singcolor.OpeLive.prototype.setHlsUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ReviewLive.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ReviewLive.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ReviewLive} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ReviewLive.toObject = function (includeInstance, msg) {
        var f, obj = {
            opeLive: (f = msg.getOpeLive()) && proto.jp.singcolor.OpeLive.toObject(includeInstance, f),
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
            onlineServer: (f = msg.getOnlineServer()) && lives_entity_pb.OnlineServer.toObject(includeInstance, f),
            streamingAppId: jspb.Message.getFieldWithDefault(msg, 4, 0),
            trtcRoomId: jspb.Message.getFieldWithDefault(msg, 5, ""),
            signature: jspb.Message.getFieldWithDefault(msg, 6, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ReviewLive}
 */
proto.jp.singcolor.ReviewLive.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ReviewLive;
    return proto.jp.singcolor.ReviewLive.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ReviewLive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ReviewLive}
 */
proto.jp.singcolor.ReviewLive.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.OpeLive;
                reader.readMessage(value, proto.jp.singcolor.OpeLive.deserializeBinaryFromReader);
                msg.setOpeLive(value);
                break;
            case 2:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            case 3:
                var value = new lives_entity_pb.OnlineServer;
                reader.readMessage(value, lives_entity_pb.OnlineServer.deserializeBinaryFromReader);
                msg.setOnlineServer(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setStreamingAppId(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setTrtcRoomId(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setSignature(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ReviewLive.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ReviewLive.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ReviewLive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ReviewLive.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOpeLive();
    if (f != null) {
        writer.writeMessage(1, f, proto.jp.singcolor.OpeLive.serializeBinaryToWriter);
    }
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(2, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getOnlineServer();
    if (f != null) {
        writer.writeMessage(3, f, lives_entity_pb.OnlineServer.serializeBinaryToWriter);
    }
    f = message.getStreamingAppId();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getTrtcRoomId();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getSignature();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
};
/**
 * optional OpeLive ope_live = 1;
 * @return {?proto.jp.singcolor.OpeLive}
 */
proto.jp.singcolor.ReviewLive.prototype.getOpeLive = function () {
    return /** @type{?proto.jp.singcolor.OpeLive} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.OpeLive, 1));
};
/**
 * @param {?proto.jp.singcolor.OpeLive|undefined} value
 * @return {!proto.jp.singcolor.ReviewLive} returns this
*/
proto.jp.singcolor.ReviewLive.prototype.setOpeLive = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ReviewLive} returns this
 */
proto.jp.singcolor.ReviewLive.prototype.clearOpeLive = function () {
    return this.setOpeLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ReviewLive.prototype.hasOpeLive = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional Song song = 2;
 * @return {?proto.Song}
 */
proto.jp.singcolor.ReviewLive.prototype.getSong = function () {
    return /** @type{?proto.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 2));
};
/**
 * @param {?proto.Song|undefined} value
 * @return {!proto.jp.singcolor.ReviewLive} returns this
*/
proto.jp.singcolor.ReviewLive.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ReviewLive} returns this
 */
proto.jp.singcolor.ReviewLive.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ReviewLive.prototype.hasSong = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional OnlineServer online_server = 3;
 * @return {?proto.OnlineServer}
 */
proto.jp.singcolor.ReviewLive.prototype.getOnlineServer = function () {
    return /** @type{?proto.OnlineServer} */ (jspb.Message.getWrapperField(this, lives_entity_pb.OnlineServer, 3));
};
/**
 * @param {?proto.OnlineServer|undefined} value
 * @return {!proto.jp.singcolor.ReviewLive} returns this
*/
proto.jp.singcolor.ReviewLive.prototype.setOnlineServer = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ReviewLive} returns this
 */
proto.jp.singcolor.ReviewLive.prototype.clearOnlineServer = function () {
    return this.setOnlineServer(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ReviewLive.prototype.hasOnlineServer = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional int64 streaming_app_id = 4;
 * @return {number}
 */
proto.jp.singcolor.ReviewLive.prototype.getStreamingAppId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ReviewLive} returns this
 */
proto.jp.singcolor.ReviewLive.prototype.setStreamingAppId = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional string trtc_room_id = 5;
 * @return {string}
 */
proto.jp.singcolor.ReviewLive.prototype.getTrtcRoomId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ReviewLive} returns this
 */
proto.jp.singcolor.ReviewLive.prototype.setTrtcRoomId = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string signature = 6;
 * @return {string}
 */
proto.jp.singcolor.ReviewLive.prototype.getSignature = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ReviewLive} returns this
 */
proto.jp.singcolor.ReviewLive.prototype.setSignature = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeGetLiveRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeGetLiveRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeGetLiveRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeGetLiveRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeGetLiveRequest}
 */
proto.jp.singcolor.OpeGetLiveRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeGetLiveRequest;
    return proto.jp.singcolor.OpeGetLiveRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeGetLiveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeGetLiveRequest}
 */
proto.jp.singcolor.OpeGetLiveRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeGetLiveRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeGetLiveRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeGetLiveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeGetLiveRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OpeGetLiveRequest.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeGetLiveRequest} returns this
 */
proto.jp.singcolor.OpeGetLiveRequest.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeGetLiveResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeGetLiveResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeGetLiveResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeGetLiveResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            live: (f = msg.getLive()) && proto.jp.singcolor.OpeLive.toObject(includeInstance, f),
            onlineServer: (f = msg.getOnlineServer()) && lives_entity_pb.OnlineServer.toObject(includeInstance, f),
            streamingAppId: jspb.Message.getFieldWithDefault(msg, 3, 0),
            trtcRoomId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            signature: jspb.Message.getFieldWithDefault(msg, 5, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeGetLiveResponse}
 */
proto.jp.singcolor.OpeGetLiveResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeGetLiveResponse;
    return proto.jp.singcolor.OpeGetLiveResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeGetLiveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeGetLiveResponse}
 */
proto.jp.singcolor.OpeGetLiveResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.OpeLive;
                reader.readMessage(value, proto.jp.singcolor.OpeLive.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            case 2:
                var value = new lives_entity_pb.OnlineServer;
                reader.readMessage(value, lives_entity_pb.OnlineServer.deserializeBinaryFromReader);
                msg.setOnlineServer(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setStreamingAppId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setTrtcRoomId(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setSignature(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeGetLiveResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeGetLiveResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeGetLiveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeGetLiveResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(1, f, proto.jp.singcolor.OpeLive.serializeBinaryToWriter);
    }
    f = message.getOnlineServer();
    if (f != null) {
        writer.writeMessage(2, f, lives_entity_pb.OnlineServer.serializeBinaryToWriter);
    }
    f = message.getStreamingAppId();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTrtcRoomId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getSignature();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
};
/**
 * optional OpeLive live = 1;
 * @return {?proto.jp.singcolor.OpeLive}
 */
proto.jp.singcolor.OpeGetLiveResponse.prototype.getLive = function () {
    return /** @type{?proto.jp.singcolor.OpeLive} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.OpeLive, 1));
};
/**
 * @param {?proto.jp.singcolor.OpeLive|undefined} value
 * @return {!proto.jp.singcolor.OpeGetLiveResponse} returns this
*/
proto.jp.singcolor.OpeGetLiveResponse.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetLiveResponse} returns this
 */
proto.jp.singcolor.OpeGetLiveResponse.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetLiveResponse.prototype.hasLive = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional OnlineServer online_server = 2;
 * @return {?proto.OnlineServer}
 */
proto.jp.singcolor.OpeGetLiveResponse.prototype.getOnlineServer = function () {
    return /** @type{?proto.OnlineServer} */ (jspb.Message.getWrapperField(this, lives_entity_pb.OnlineServer, 2));
};
/**
 * @param {?proto.OnlineServer|undefined} value
 * @return {!proto.jp.singcolor.OpeGetLiveResponse} returns this
*/
proto.jp.singcolor.OpeGetLiveResponse.prototype.setOnlineServer = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetLiveResponse} returns this
 */
proto.jp.singcolor.OpeGetLiveResponse.prototype.clearOnlineServer = function () {
    return this.setOnlineServer(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetLiveResponse.prototype.hasOnlineServer = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional int64 streaming_app_id = 3;
 * @return {number}
 */
proto.jp.singcolor.OpeGetLiveResponse.prototype.getStreamingAppId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OpeGetLiveResponse} returns this
 */
proto.jp.singcolor.OpeGetLiveResponse.prototype.setStreamingAppId = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional string trtc_room_id = 4;
 * @return {string}
 */
proto.jp.singcolor.OpeGetLiveResponse.prototype.getTrtcRoomId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeGetLiveResponse} returns this
 */
proto.jp.singcolor.OpeGetLiveResponse.prototype.setTrtcRoomId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string signature = 5;
 * @return {string}
 */
proto.jp.singcolor.OpeGetLiveResponse.prototype.getSignature = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeGetLiveResponse} returns this
 */
proto.jp.singcolor.OpeGetLiveResponse.prototype.setSignature = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeListLivesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeListLivesRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeListLivesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeListLivesRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            onAir: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
            liveTitleKeyword: jspb.Message.getFieldWithDefault(msg, 2, ""),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeListLivesRequest}
 */
proto.jp.singcolor.OpeListLivesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeListLivesRequest;
    return proto.jp.singcolor.OpeListLivesRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeListLivesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeListLivesRequest}
 */
proto.jp.singcolor.OpeListLivesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setOnAir(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveTitleKeyword(value);
                break;
            case 3:
                var value = new page_entity_pb.SimplePaginationRequest;
                reader.readMessage(value, page_entity_pb.SimplePaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeListLivesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeListLivesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeListLivesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeListLivesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOnAir();
    if (f) {
        writer.writeBool(1, f);
    }
    f = message.getLiveTitleKeyword();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(3, f, page_entity_pb.SimplePaginationRequest.serializeBinaryToWriter);
    }
};
/**
 * optional bool on_air = 1;
 * @return {boolean}
 */
proto.jp.singcolor.OpeListLivesRequest.prototype.getOnAir = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.OpeListLivesRequest} returns this
 */
proto.jp.singcolor.OpeListLivesRequest.prototype.setOnAir = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
/**
 * optional string live_title_keyword = 2;
 * @return {string}
 */
proto.jp.singcolor.OpeListLivesRequest.prototype.getLiveTitleKeyword = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeListLivesRequest} returns this
 */
proto.jp.singcolor.OpeListLivesRequest.prototype.setLiveTitleKeyword = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional SimplePaginationRequest page = 3;
 * @return {?proto.SimplePaginationRequest}
 */
proto.jp.singcolor.OpeListLivesRequest.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationRequest, 3));
};
/**
 * @param {?proto.SimplePaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.OpeListLivesRequest} returns this
*/
proto.jp.singcolor.OpeListLivesRequest.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeListLivesRequest} returns this
 */
proto.jp.singcolor.OpeListLivesRequest.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeListLivesRequest.prototype.hasPage = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.OpeListLivesResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeListLivesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeListLivesResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeListLivesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeListLivesResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            livesList: jspb.Message.toObjectList(msg.getLivesList(), proto.jp.singcolor.OpeLive.toObject, includeInstance),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationResult.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeListLivesResponse}
 */
proto.jp.singcolor.OpeListLivesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeListLivesResponse;
    return proto.jp.singcolor.OpeListLivesResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeListLivesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeListLivesResponse}
 */
proto.jp.singcolor.OpeListLivesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.OpeLive;
                reader.readMessage(value, proto.jp.singcolor.OpeLive.deserializeBinaryFromReader);
                msg.addLives(value);
                break;
            case 2:
                var value = new page_entity_pb.SimplePaginationResult;
                reader.readMessage(value, page_entity_pb.SimplePaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeListLivesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeListLivesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeListLivesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeListLivesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLivesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.OpeLive.serializeBinaryToWriter);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.SimplePaginationResult.serializeBinaryToWriter);
    }
};
/**
 * repeated OpeLive lives = 1;
 * @return {!Array<!proto.jp.singcolor.OpeLive>}
 */
proto.jp.singcolor.OpeListLivesResponse.prototype.getLivesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.OpeLive>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.OpeLive, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.OpeLive>} value
 * @return {!proto.jp.singcolor.OpeListLivesResponse} returns this
*/
proto.jp.singcolor.OpeListLivesResponse.prototype.setLivesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.OpeLive=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.OpeLive}
 */
proto.jp.singcolor.OpeListLivesResponse.prototype.addLives = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.OpeLive, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.OpeListLivesResponse} returns this
 */
proto.jp.singcolor.OpeListLivesResponse.prototype.clearLivesList = function () {
    return this.setLivesList([]);
};
/**
 * optional SimplePaginationResult page = 2;
 * @return {?proto.SimplePaginationResult}
 */
proto.jp.singcolor.OpeListLivesResponse.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationResult, 2));
};
/**
 * @param {?proto.SimplePaginationResult|undefined} value
 * @return {!proto.jp.singcolor.OpeListLivesResponse} returns this
*/
proto.jp.singcolor.OpeListLivesResponse.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeListLivesResponse} returns this
 */
proto.jp.singcolor.OpeListLivesResponse.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeListLivesResponse.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.WarnLiveRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.WarnLiveRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.WarnLiveRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.WarnLiveRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            violationCategoryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            reportId: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.WarnLiveRequest}
 */
proto.jp.singcolor.WarnLiveRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.WarnLiveRequest;
    return proto.jp.singcolor.WarnLiveRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.WarnLiveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.WarnLiveRequest}
 */
proto.jp.singcolor.WarnLiveRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setViolationCategoryId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setReportId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.WarnLiveRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.WarnLiveRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.WarnLiveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.WarnLiveRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getViolationCategoryId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getReportId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.WarnLiveRequest.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.WarnLiveRequest} returns this
 */
proto.jp.singcolor.WarnLiveRequest.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string violation_category_id = 2;
 * @return {string}
 */
proto.jp.singcolor.WarnLiveRequest.prototype.getViolationCategoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.WarnLiveRequest} returns this
 */
proto.jp.singcolor.WarnLiveRequest.prototype.setViolationCategoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string report_id = 3;
 * @return {string}
 */
proto.jp.singcolor.WarnLiveRequest.prototype.getReportId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.WarnLiveRequest} returns this
 */
proto.jp.singcolor.WarnLiveRequest.prototype.setReportId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.WarnLiveResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.WarnLiveResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.WarnLiveResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.WarnLiveResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.WarnLiveResponse}
 */
proto.jp.singcolor.WarnLiveResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.WarnLiveResponse;
    return proto.jp.singcolor.WarnLiveResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.WarnLiveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.WarnLiveResponse}
 */
proto.jp.singcolor.WarnLiveResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.WarnLiveResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.WarnLiveResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.WarnLiveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.WarnLiveResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ForceStopLiveRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ForceStopLiveRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ForceStopLiveRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ForceStopLiveRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            violationCategoryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            reportId: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ForceStopLiveRequest}
 */
proto.jp.singcolor.ForceStopLiveRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ForceStopLiveRequest;
    return proto.jp.singcolor.ForceStopLiveRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ForceStopLiveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ForceStopLiveRequest}
 */
proto.jp.singcolor.ForceStopLiveRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setViolationCategoryId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setReportId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ForceStopLiveRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ForceStopLiveRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ForceStopLiveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ForceStopLiveRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getViolationCategoryId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getReportId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ForceStopLiveRequest.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ForceStopLiveRequest} returns this
 */
proto.jp.singcolor.ForceStopLiveRequest.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string violation_category_id = 2;
 * @return {string}
 */
proto.jp.singcolor.ForceStopLiveRequest.prototype.getViolationCategoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ForceStopLiveRequest} returns this
 */
proto.jp.singcolor.ForceStopLiveRequest.prototype.setViolationCategoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string report_id = 3;
 * @return {string}
 */
proto.jp.singcolor.ForceStopLiveRequest.prototype.getReportId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ForceStopLiveRequest} returns this
 */
proto.jp.singcolor.ForceStopLiveRequest.prototype.setReportId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ForceStopLiveResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ForceStopLiveResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ForceStopLiveResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ForceStopLiveResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ForceStopLiveResponse}
 */
proto.jp.singcolor.ForceStopLiveResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ForceStopLiveResponse;
    return proto.jp.singcolor.ForceStopLiveResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ForceStopLiveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ForceStopLiveResponse}
 */
proto.jp.singcolor.ForceStopLiveResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ForceStopLiveResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ForceStopLiveResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ForceStopLiveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ForceStopLiveResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListReviewLivesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListReviewLivesRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListReviewLivesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListReviewLivesRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            limit: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListReviewLivesRequest}
 */
proto.jp.singcolor.ListReviewLivesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListReviewLivesRequest;
    return proto.jp.singcolor.ListReviewLivesRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListReviewLivesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListReviewLivesRequest}
 */
proto.jp.singcolor.ListReviewLivesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListReviewLivesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListReviewLivesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListReviewLivesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListReviewLivesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLimit();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
};
/**
 * optional int64 limit = 1;
 * @return {number}
 */
proto.jp.singcolor.ListReviewLivesRequest.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListReviewLivesRequest} returns this
 */
proto.jp.singcolor.ListReviewLivesRequest.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListReviewLivesResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListReviewLivesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListReviewLivesResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListReviewLivesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListReviewLivesResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            reviewLivesList: jspb.Message.toObjectList(msg.getReviewLivesList(), proto.jp.singcolor.ReviewLive.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListReviewLivesResponse}
 */
proto.jp.singcolor.ListReviewLivesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListReviewLivesResponse;
    return proto.jp.singcolor.ListReviewLivesResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListReviewLivesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListReviewLivesResponse}
 */
proto.jp.singcolor.ListReviewLivesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.ReviewLive;
                reader.readMessage(value, proto.jp.singcolor.ReviewLive.deserializeBinaryFromReader);
                msg.addReviewLives(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListReviewLivesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListReviewLivesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListReviewLivesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListReviewLivesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getReviewLivesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.ReviewLive.serializeBinaryToWriter);
    }
};
/**
 * repeated ReviewLive review_lives = 1;
 * @return {!Array<!proto.jp.singcolor.ReviewLive>}
 */
proto.jp.singcolor.ListReviewLivesResponse.prototype.getReviewLivesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.ReviewLive>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.ReviewLive, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.ReviewLive>} value
 * @return {!proto.jp.singcolor.ListReviewLivesResponse} returns this
*/
proto.jp.singcolor.ListReviewLivesResponse.prototype.setReviewLivesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.ReviewLive=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ReviewLive}
 */
proto.jp.singcolor.ListReviewLivesResponse.prototype.addReviewLives = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.ReviewLive, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListReviewLivesResponse} returns this
 */
proto.jp.singcolor.ListReviewLivesResponse.prototype.clearReviewLivesList = function () {
    return this.setReviewLivesList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetRecordHLSDataV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetRecordHLSDataV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetRecordHLSDataV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetRecordHLSDataV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetRecordHLSDataV1Request}
 */
proto.jp.singcolor.GetRecordHLSDataV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetRecordHLSDataV1Request;
    return proto.jp.singcolor.GetRecordHLSDataV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetRecordHLSDataV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetRecordHLSDataV1Request}
 */
proto.jp.singcolor.GetRecordHLSDataV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetRecordHLSDataV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetRecordHLSDataV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetRecordHLSDataV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetRecordHLSDataV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetRecordHLSDataV1Request.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetRecordHLSDataV1Request} returns this
 */
proto.jp.singcolor.GetRecordHLSDataV1Request.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetRecordHLSDataV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetRecordHLSDataV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetRecordHLSDataV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetRecordHLSDataV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            data: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetRecordHLSDataV1Response}
 */
proto.jp.singcolor.GetRecordHLSDataV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetRecordHLSDataV1Response;
    return proto.jp.singcolor.GetRecordHLSDataV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetRecordHLSDataV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetRecordHLSDataV1Response}
 */
proto.jp.singcolor.GetRecordHLSDataV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setData(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetRecordHLSDataV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetRecordHLSDataV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetRecordHLSDataV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetRecordHLSDataV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getData();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string data = 1;
 * @return {string}
 */
proto.jp.singcolor.GetRecordHLSDataV1Response.prototype.getData = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetRecordHLSDataV1Response} returns this
 */
proto.jp.singcolor.GetRecordHLSDataV1Response.prototype.setData = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
goog.object.extend(exports, proto.jp.singcolor);
