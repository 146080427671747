"use strict";
// source: one_phrase_events_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var users_entity_pb = require('./users_entity_pb.js');
goog.object.extend(proto, users_entity_pb);
var karaoke_entity_pb = require('./karaoke_entity_pb.js');
goog.object.extend(proto, karaoke_entity_pb);
goog.exportSymbol('proto.OnePhraseEvent', null, global);
goog.exportSymbol('proto.OnePhraseEventEntry', null, global);
goog.exportSymbol('proto.OnePhraseEventEntryItem', null, global);
goog.exportSymbol('proto.OnePhraseEventEntryItemWithVotePoint', null, global);
goog.exportSymbol('proto.OnePhraseEventItem', null, global);
goog.exportSymbol('proto.OnePhraseEventPrize', null, global);
goog.exportSymbol('proto.OnePhraseEventSpan', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OnePhraseEvent = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OnePhraseEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.OnePhraseEvent.displayName = 'proto.OnePhraseEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OnePhraseEventEntry = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OnePhraseEventEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.OnePhraseEventEntry.displayName = 'proto.OnePhraseEventEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OnePhraseEventItem = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OnePhraseEventItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.OnePhraseEventItem.displayName = 'proto.OnePhraseEventItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OnePhraseEventEntryItem = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OnePhraseEventEntryItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.OnePhraseEventEntryItem.displayName = 'proto.OnePhraseEventEntryItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OnePhraseEventEntryItemWithVotePoint = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OnePhraseEventEntryItemWithVotePoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.OnePhraseEventEntryItemWithVotePoint.displayName = 'proto.OnePhraseEventEntryItemWithVotePoint';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.OnePhraseEvent.prototype.toObject = function (opt_includeInstance) {
        return proto.OnePhraseEvent.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.OnePhraseEvent} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.OnePhraseEvent.toObject = function (includeInstance, msg) {
        var f, obj = {
            onePhraseEventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            eventWebUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
            title: jspb.Message.getFieldWithDefault(msg, 3, ""),
            description: jspb.Message.getFieldWithDefault(msg, 4, ""),
            periodText: jspb.Message.getFieldWithDefault(msg, 5, ""),
            songId: jspb.Message.getFieldWithDefault(msg, 6, ""),
            startRecordPosition: jspb.Message.getFieldWithDefault(msg, 7, 0),
            startPlayPosition: jspb.Message.getFieldWithDefault(msg, 8, 0),
            endPlayPosition: jspb.Message.getFieldWithDefault(msg, 9, 0),
            endRecordPosition: jspb.Message.getFieldWithDefault(msg, 10, 0),
            entryStartAt: jspb.Message.getFieldWithDefault(msg, 11, 0),
            entryEndAt: jspb.Message.getFieldWithDefault(msg, 12, 0),
            voteStartAt: jspb.Message.getFieldWithDefault(msg, 13, 0),
            voteEndAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
            rankingPublishedStartAt: jspb.Message.getFieldWithDefault(msg, 15, 0),
            rankingPublishedEndAt: jspb.Message.getFieldWithDefault(msg, 16, 0),
            eventSpan: jspb.Message.getFieldWithDefault(msg, 17, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OnePhraseEvent}
 */
proto.OnePhraseEvent.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.OnePhraseEvent;
    return proto.OnePhraseEvent.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OnePhraseEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OnePhraseEvent}
 */
proto.OnePhraseEvent.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOnePhraseEventId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setEventWebUrl(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setTitle(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setDescription(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setPeriodText(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongId(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setStartRecordPosition(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setStartPlayPosition(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setEndPlayPosition(value);
                break;
            case 10:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setEndRecordPosition(value);
                break;
            case 11:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setEntryStartAt(value);
                break;
            case 12:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setEntryEndAt(value);
                break;
            case 13:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setVoteStartAt(value);
                break;
            case 14:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setVoteEndAt(value);
                break;
            case 15:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRankingPublishedStartAt(value);
                break;
            case 16:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRankingPublishedEndAt(value);
                break;
            case 17:
                var value = /** @type {!proto.OnePhraseEventSpan} */ (reader.readEnum());
                msg.setEventSpan(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OnePhraseEvent.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.OnePhraseEvent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OnePhraseEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OnePhraseEvent.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOnePhraseEventId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getEventWebUrl();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getTitle();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getDescription();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getPeriodText();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getSongId();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getStartRecordPosition();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getStartPlayPosition();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
    f = message.getEndPlayPosition();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
    f = message.getEndRecordPosition();
    if (f !== 0) {
        writer.writeInt64(10, f);
    }
    f = message.getEntryStartAt();
    if (f !== 0) {
        writer.writeInt64(11, f);
    }
    f = message.getEntryEndAt();
    if (f !== 0) {
        writer.writeInt64(12, f);
    }
    f = message.getVoteStartAt();
    if (f !== 0) {
        writer.writeInt64(13, f);
    }
    f = message.getVoteEndAt();
    if (f !== 0) {
        writer.writeInt64(14, f);
    }
    f = message.getRankingPublishedStartAt();
    if (f !== 0) {
        writer.writeInt64(15, f);
    }
    f = message.getRankingPublishedEndAt();
    if (f !== 0) {
        writer.writeInt64(16, f);
    }
    f = message.getEventSpan();
    if (f !== 0.0) {
        writer.writeEnum(17, f);
    }
};
/**
 * optional string one_phrase_event_id = 1;
 * @return {string}
 */
proto.OnePhraseEvent.prototype.getOnePhraseEventId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setOnePhraseEventId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string event_web_url = 2;
 * @return {string}
 */
proto.OnePhraseEvent.prototype.getEventWebUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setEventWebUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string title = 3;
 * @return {string}
 */
proto.OnePhraseEvent.prototype.getTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setTitle = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string description = 4;
 * @return {string}
 */
proto.OnePhraseEvent.prototype.getDescription = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setDescription = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string period_text = 5;
 * @return {string}
 */
proto.OnePhraseEvent.prototype.getPeriodText = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setPeriodText = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string song_id = 6;
 * @return {string}
 */
proto.OnePhraseEvent.prototype.getSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional int64 start_record_position = 7;
 * @return {number}
 */
proto.OnePhraseEvent.prototype.getStartRecordPosition = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setStartRecordPosition = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional int64 start_play_position = 8;
 * @return {number}
 */
proto.OnePhraseEvent.prototype.getStartPlayPosition = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setStartPlayPosition = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional int64 end_play_position = 9;
 * @return {number}
 */
proto.OnePhraseEvent.prototype.getEndPlayPosition = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setEndPlayPosition = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
/**
 * optional int64 end_record_position = 10;
 * @return {number}
 */
proto.OnePhraseEvent.prototype.getEndRecordPosition = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};
/**
 * @param {number} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setEndRecordPosition = function (value) {
    return jspb.Message.setProto3IntField(this, 10, value);
};
/**
 * optional int64 entry_start_at = 11;
 * @return {number}
 */
proto.OnePhraseEvent.prototype.getEntryStartAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};
/**
 * @param {number} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setEntryStartAt = function (value) {
    return jspb.Message.setProto3IntField(this, 11, value);
};
/**
 * optional int64 entry_end_at = 12;
 * @return {number}
 */
proto.OnePhraseEvent.prototype.getEntryEndAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};
/**
 * @param {number} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setEntryEndAt = function (value) {
    return jspb.Message.setProto3IntField(this, 12, value);
};
/**
 * optional int64 vote_start_at = 13;
 * @return {number}
 */
proto.OnePhraseEvent.prototype.getVoteStartAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};
/**
 * @param {number} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setVoteStartAt = function (value) {
    return jspb.Message.setProto3IntField(this, 13, value);
};
/**
 * optional int64 vote_end_at = 14;
 * @return {number}
 */
proto.OnePhraseEvent.prototype.getVoteEndAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};
/**
 * @param {number} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setVoteEndAt = function (value) {
    return jspb.Message.setProto3IntField(this, 14, value);
};
/**
 * optional int64 ranking_published_start_at = 15;
 * @return {number}
 */
proto.OnePhraseEvent.prototype.getRankingPublishedStartAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};
/**
 * @param {number} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setRankingPublishedStartAt = function (value) {
    return jspb.Message.setProto3IntField(this, 15, value);
};
/**
 * optional int64 ranking_published_end_at = 16;
 * @return {number}
 */
proto.OnePhraseEvent.prototype.getRankingPublishedEndAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};
/**
 * @param {number} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setRankingPublishedEndAt = function (value) {
    return jspb.Message.setProto3IntField(this, 16, value);
};
/**
 * optional OnePhraseEventSpan event_span = 17;
 * @return {!proto.OnePhraseEventSpan}
 */
proto.OnePhraseEvent.prototype.getEventSpan = function () {
    return /** @type {!proto.OnePhraseEventSpan} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};
/**
 * @param {!proto.OnePhraseEventSpan} value
 * @return {!proto.OnePhraseEvent} returns this
 */
proto.OnePhraseEvent.prototype.setEventSpan = function (value) {
    return jspb.Message.setProto3EnumField(this, 17, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.OnePhraseEventEntry.prototype.toObject = function (opt_includeInstance) {
        return proto.OnePhraseEventEntry.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.OnePhraseEventEntry} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.OnePhraseEventEntry.toObject = function (includeInstance, msg) {
        var f, obj = {
            onePhraseEventEntryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            onePhraseEventId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            audioUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
            audioStreamingUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
            duration: jspb.Message.getFieldWithDefault(msg, 5, 0),
            isAnonymousEntry: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
            anonymousNickname: jspb.Message.getFieldWithDefault(msg, 7, ""),
            published: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
            createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OnePhraseEventEntry}
 */
proto.OnePhraseEventEntry.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.OnePhraseEventEntry;
    return proto.OnePhraseEventEntry.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OnePhraseEventEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OnePhraseEventEntry}
 */
proto.OnePhraseEventEntry.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOnePhraseEventEntryId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setOnePhraseEventId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setAudioUrl(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setAudioStreamingUrl(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDuration(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsAnonymousEntry(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setAnonymousNickname(value);
                break;
            case 10:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setPublished(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OnePhraseEventEntry.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.OnePhraseEventEntry.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OnePhraseEventEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OnePhraseEventEntry.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOnePhraseEventEntryId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getOnePhraseEventId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getAudioUrl();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getAudioStreamingUrl();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getDuration();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getIsAnonymousEntry();
    if (f) {
        writer.writeBool(6, f);
    }
    f = message.getAnonymousNickname();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
    f = message.getPublished();
    if (f) {
        writer.writeBool(10, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
};
/**
 * optional string one_phrase_event_entry_id = 1;
 * @return {string}
 */
proto.OnePhraseEventEntry.prototype.getOnePhraseEventEntryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.OnePhraseEventEntry} returns this
 */
proto.OnePhraseEventEntry.prototype.setOnePhraseEventEntryId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string one_phrase_event_id = 2;
 * @return {string}
 */
proto.OnePhraseEventEntry.prototype.getOnePhraseEventId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.OnePhraseEventEntry} returns this
 */
proto.OnePhraseEventEntry.prototype.setOnePhraseEventId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string audio_url = 3;
 * @return {string}
 */
proto.OnePhraseEventEntry.prototype.getAudioUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.OnePhraseEventEntry} returns this
 */
proto.OnePhraseEventEntry.prototype.setAudioUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string audio_streaming_url = 4;
 * @return {string}
 */
proto.OnePhraseEventEntry.prototype.getAudioStreamingUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.OnePhraseEventEntry} returns this
 */
proto.OnePhraseEventEntry.prototype.setAudioStreamingUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional int64 duration = 5;
 * @return {number}
 */
proto.OnePhraseEventEntry.prototype.getDuration = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.OnePhraseEventEntry} returns this
 */
proto.OnePhraseEventEntry.prototype.setDuration = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional bool is_anonymous_entry = 6;
 * @return {boolean}
 */
proto.OnePhraseEventEntry.prototype.getIsAnonymousEntry = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.OnePhraseEventEntry} returns this
 */
proto.OnePhraseEventEntry.prototype.setIsAnonymousEntry = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
/**
 * optional string anonymous_nickname = 7;
 * @return {string}
 */
proto.OnePhraseEventEntry.prototype.getAnonymousNickname = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.OnePhraseEventEntry} returns this
 */
proto.OnePhraseEventEntry.prototype.setAnonymousNickname = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional bool published = 10;
 * @return {boolean}
 */
proto.OnePhraseEventEntry.prototype.getPublished = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};
/**
 * @param {boolean} value
 * @return {!proto.OnePhraseEventEntry} returns this
 */
proto.OnePhraseEventEntry.prototype.setPublished = function (value) {
    return jspb.Message.setProto3BooleanField(this, 10, value);
};
/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.OnePhraseEventEntry.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.OnePhraseEventEntry} returns this
 */
proto.OnePhraseEventEntry.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.OnePhraseEventItem.prototype.toObject = function (opt_includeInstance) {
        return proto.OnePhraseEventItem.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.OnePhraseEventItem} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.OnePhraseEventItem.toObject = function (includeInstance, msg) {
        var f, obj = {
            event: (f = msg.getEvent()) && proto.OnePhraseEvent.toObject(includeInstance, f),
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
            entry: (f = msg.getEntry()) && proto.OnePhraseEventEntryItem.toObject(includeInstance, f),
            remainingVoteCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OnePhraseEventItem}
 */
proto.OnePhraseEventItem.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.OnePhraseEventItem;
    return proto.OnePhraseEventItem.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OnePhraseEventItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OnePhraseEventItem}
 */
proto.OnePhraseEventItem.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.OnePhraseEvent;
                reader.readMessage(value, proto.OnePhraseEvent.deserializeBinaryFromReader);
                msg.setEvent(value);
                break;
            case 2:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            case 3:
                var value = new proto.OnePhraseEventEntryItem;
                reader.readMessage(value, proto.OnePhraseEventEntryItem.deserializeBinaryFromReader);
                msg.setEntry(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRemainingVoteCount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OnePhraseEventItem.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.OnePhraseEventItem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OnePhraseEventItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OnePhraseEventItem.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEvent();
    if (f != null) {
        writer.writeMessage(1, f, proto.OnePhraseEvent.serializeBinaryToWriter);
    }
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(2, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getEntry();
    if (f != null) {
        writer.writeMessage(3, f, proto.OnePhraseEventEntryItem.serializeBinaryToWriter);
    }
    f = message.getRemainingVoteCount();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
};
/**
 * optional OnePhraseEvent event = 1;
 * @return {?proto.OnePhraseEvent}
 */
proto.OnePhraseEventItem.prototype.getEvent = function () {
    return /** @type{?proto.OnePhraseEvent} */ (jspb.Message.getWrapperField(this, proto.OnePhraseEvent, 1));
};
/**
 * @param {?proto.OnePhraseEvent|undefined} value
 * @return {!proto.OnePhraseEventItem} returns this
*/
proto.OnePhraseEventItem.prototype.setEvent = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.OnePhraseEventItem} returns this
 */
proto.OnePhraseEventItem.prototype.clearEvent = function () {
    return this.setEvent(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.OnePhraseEventItem.prototype.hasEvent = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional Song song = 2;
 * @return {?proto.Song}
 */
proto.OnePhraseEventItem.prototype.getSong = function () {
    return /** @type{?proto.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 2));
};
/**
 * @param {?proto.Song|undefined} value
 * @return {!proto.OnePhraseEventItem} returns this
*/
proto.OnePhraseEventItem.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.OnePhraseEventItem} returns this
 */
proto.OnePhraseEventItem.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.OnePhraseEventItem.prototype.hasSong = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional OnePhraseEventEntryItem entry = 3;
 * @return {?proto.OnePhraseEventEntryItem}
 */
proto.OnePhraseEventItem.prototype.getEntry = function () {
    return /** @type{?proto.OnePhraseEventEntryItem} */ (jspb.Message.getWrapperField(this, proto.OnePhraseEventEntryItem, 3));
};
/**
 * @param {?proto.OnePhraseEventEntryItem|undefined} value
 * @return {!proto.OnePhraseEventItem} returns this
*/
proto.OnePhraseEventItem.prototype.setEntry = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.OnePhraseEventItem} returns this
 */
proto.OnePhraseEventItem.prototype.clearEntry = function () {
    return this.setEntry(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.OnePhraseEventItem.prototype.hasEntry = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional int64 remaining_vote_count = 4;
 * @return {number}
 */
proto.OnePhraseEventItem.prototype.getRemainingVoteCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.OnePhraseEventItem} returns this
 */
proto.OnePhraseEventItem.prototype.setRemainingVoteCount = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.OnePhraseEventEntryItem.prototype.toObject = function (opt_includeInstance) {
        return proto.OnePhraseEventEntryItem.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.OnePhraseEventEntryItem} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.OnePhraseEventEntryItem.toObject = function (includeInstance, msg) {
        var f, obj = {
            entry: (f = msg.getEntry()) && proto.OnePhraseEventEntry.toObject(includeInstance, f),
            userItem: (f = msg.getUserItem()) && users_entity_pb.UserItemWithFollow.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OnePhraseEventEntryItem}
 */
proto.OnePhraseEventEntryItem.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.OnePhraseEventEntryItem;
    return proto.OnePhraseEventEntryItem.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OnePhraseEventEntryItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OnePhraseEventEntryItem}
 */
proto.OnePhraseEventEntryItem.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.OnePhraseEventEntry;
                reader.readMessage(value, proto.OnePhraseEventEntry.deserializeBinaryFromReader);
                msg.setEntry(value);
                break;
            case 2:
                var value = new users_entity_pb.UserItemWithFollow;
                reader.readMessage(value, users_entity_pb.UserItemWithFollow.deserializeBinaryFromReader);
                msg.setUserItem(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OnePhraseEventEntryItem.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.OnePhraseEventEntryItem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OnePhraseEventEntryItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OnePhraseEventEntryItem.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEntry();
    if (f != null) {
        writer.writeMessage(1, f, proto.OnePhraseEventEntry.serializeBinaryToWriter);
    }
    f = message.getUserItem();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserItemWithFollow.serializeBinaryToWriter);
    }
};
/**
 * optional OnePhraseEventEntry entry = 1;
 * @return {?proto.OnePhraseEventEntry}
 */
proto.OnePhraseEventEntryItem.prototype.getEntry = function () {
    return /** @type{?proto.OnePhraseEventEntry} */ (jspb.Message.getWrapperField(this, proto.OnePhraseEventEntry, 1));
};
/**
 * @param {?proto.OnePhraseEventEntry|undefined} value
 * @return {!proto.OnePhraseEventEntryItem} returns this
*/
proto.OnePhraseEventEntryItem.prototype.setEntry = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.OnePhraseEventEntryItem} returns this
 */
proto.OnePhraseEventEntryItem.prototype.clearEntry = function () {
    return this.setEntry(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.OnePhraseEventEntryItem.prototype.hasEntry = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserItemWithFollow user_item = 2;
 * @return {?proto.UserItemWithFollow}
 */
proto.OnePhraseEventEntryItem.prototype.getUserItem = function () {
    return /** @type{?proto.UserItemWithFollow} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItemWithFollow, 2));
};
/**
 * @param {?proto.UserItemWithFollow|undefined} value
 * @return {!proto.OnePhraseEventEntryItem} returns this
*/
proto.OnePhraseEventEntryItem.prototype.setUserItem = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.OnePhraseEventEntryItem} returns this
 */
proto.OnePhraseEventEntryItem.prototype.clearUserItem = function () {
    return this.setUserItem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.OnePhraseEventEntryItem.prototype.hasUserItem = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.OnePhraseEventEntryItemWithVotePoint.prototype.toObject = function (opt_includeInstance) {
        return proto.OnePhraseEventEntryItemWithVotePoint.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.OnePhraseEventEntryItemWithVotePoint} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.OnePhraseEventEntryItemWithVotePoint.toObject = function (includeInstance, msg) {
        var f, obj = {
            entry: (f = msg.getEntry()) && proto.OnePhraseEventEntryItem.toObject(includeInstance, f),
            votePoint: jspb.Message.getFieldWithDefault(msg, 2, 0),
            prise: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OnePhraseEventEntryItemWithVotePoint}
 */
proto.OnePhraseEventEntryItemWithVotePoint.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.OnePhraseEventEntryItemWithVotePoint;
    return proto.OnePhraseEventEntryItemWithVotePoint.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OnePhraseEventEntryItemWithVotePoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OnePhraseEventEntryItemWithVotePoint}
 */
proto.OnePhraseEventEntryItemWithVotePoint.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.OnePhraseEventEntryItem;
                reader.readMessage(value, proto.OnePhraseEventEntryItem.deserializeBinaryFromReader);
                msg.setEntry(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setVotePoint(value);
                break;
            case 3:
                var value = /** @type {!proto.OnePhraseEventPrize} */ (reader.readEnum());
                msg.setPrise(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OnePhraseEventEntryItemWithVotePoint.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.OnePhraseEventEntryItemWithVotePoint.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OnePhraseEventEntryItemWithVotePoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OnePhraseEventEntryItemWithVotePoint.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEntry();
    if (f != null) {
        writer.writeMessage(1, f, proto.OnePhraseEventEntryItem.serializeBinaryToWriter);
    }
    f = message.getVotePoint();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getPrise();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
};
/**
 * optional OnePhraseEventEntryItem entry = 1;
 * @return {?proto.OnePhraseEventEntryItem}
 */
proto.OnePhraseEventEntryItemWithVotePoint.prototype.getEntry = function () {
    return /** @type{?proto.OnePhraseEventEntryItem} */ (jspb.Message.getWrapperField(this, proto.OnePhraseEventEntryItem, 1));
};
/**
 * @param {?proto.OnePhraseEventEntryItem|undefined} value
 * @return {!proto.OnePhraseEventEntryItemWithVotePoint} returns this
*/
proto.OnePhraseEventEntryItemWithVotePoint.prototype.setEntry = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.OnePhraseEventEntryItemWithVotePoint} returns this
 */
proto.OnePhraseEventEntryItemWithVotePoint.prototype.clearEntry = function () {
    return this.setEntry(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.OnePhraseEventEntryItemWithVotePoint.prototype.hasEntry = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional int64 vote_point = 2;
 * @return {number}
 */
proto.OnePhraseEventEntryItemWithVotePoint.prototype.getVotePoint = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.OnePhraseEventEntryItemWithVotePoint} returns this
 */
proto.OnePhraseEventEntryItemWithVotePoint.prototype.setVotePoint = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional OnePhraseEventPrize prise = 3;
 * @return {!proto.OnePhraseEventPrize}
 */
proto.OnePhraseEventEntryItemWithVotePoint.prototype.getPrise = function () {
    return /** @type {!proto.OnePhraseEventPrize} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.OnePhraseEventPrize} value
 * @return {!proto.OnePhraseEventEntryItemWithVotePoint} returns this
 */
proto.OnePhraseEventEntryItemWithVotePoint.prototype.setPrise = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * @enum {number}
 */
proto.OnePhraseEventSpan = {
    ONE_PHRASE_EVENT_SPAN_UNKNOWN: 0,
    ONE_PHRASE_EVENT_SPAN_ENTRY: 1,
    ONE_PHRASE_EVENT_SPAN_VOTE: 2,
    ONE_PHRASE_EVENT_SPAN_RANKING_PUBLISHED: 3,
    ONE_PHRASE_EVENT_SPAN_RANKING_CALCULATING: 4,
    ONE_PHRASE_EVENT_SPAN_WAITING_VOTE_PERIOD: 5
};
/**
 * @enum {number}
 */
proto.OnePhraseEventPrize = {
    ONE_PHRASE_EVENT_PRIZE_UNKNOWN: 0,
    ONE_PHRASE_EVENT_PRIZE_PLATINUM: 1,
    ONE_PHRASE_EVENT_PRIZE_GOLD: 2,
    ONE_PHRASE_EVENT_PRIZE_SILVER: 3,
    ONE_PHRASE_EVENT_PRIZE_BRONZE: 4,
    ONE_PHRASE_EVENT_PRIZE_SPIRIT: 5,
    ONE_PHRASE_EVENT_PRIZE_NO_PRIZE: 6
};
goog.object.extend(exports, proto);
